import React from 'react';
import "./MobileNavbar.css";
import chatIcon from "../../../Media/chatIconInverted.png";
import accountIcon from "../../../Media/accountIconInverted.png";
import menuIcon from "../../../Media/menuIconInverted.png";
import searchIcon from "../../../Media/searchIconInverted.png";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setViewProfileID, toggleShowMenu, toggleShowChat } from '../../../GlobalState/AppSlice';

function MobileNavbar({ top, color = "#0758ff", unreadMessages, newMessageCircleLarge }) {

  const userID = useSelector(state => state.appSlice.userID);
  const profileData = useSelector(state => state.appSlice.profileData);
  const dispatcher = useDispatch();

  return (
    <div className={'mobileNavbar' + (top ? " mobileNavbarTop" : "")}>        
      {/* Account */}
      <Link to={"/profile/" + userID} title="Account" className={`mobileTopNavButton ${profileData?.images?.main ? 'withImage' : ''}`} onClick={() => dispatcher(setViewProfileID())}>
        {profileData?.images?.main ? 
          <img src={profileData?.images?.main?.downloadURL} className='mobileTopNavLinkImg mobileRoundImage' style={{ backgroundColor: color }} alt="Profile" />
          :
          <img src={accountIcon} style={{ backgroundColor: color }} alt="Account" />              
        }
        <div className='mobileTopNavButtonLabel'>Account</div>
      </Link>   

      {/* Menu */}
      <div title="Menu" onClick={() => dispatcher(toggleShowMenu())} className='mobileTopNavButton'>
        <img src={menuIcon} style={{ backgroundColor: color }} alt="Menu" />
        <div className='mobileTopNavButtonLabel'>Menu</div>
      </div> 

      {/* Search */}     
      <div>
        <Link to={"/search"} title="Donor Search" className='mobileTopNavButton' onClick={() => dispatcher(setViewProfileID())}>
            <img src={searchIcon} style={{ backgroundColor: color }} alt="Search" />
            <div className='mobileTopNavButtonLabel'>Search</div>
        </Link>   
      </div> 

      {/* Chats */}
      <div title="Chats" className='mobileTopNavButton' onClick={() => dispatcher(toggleShowChat())}>
        <img src={chatIcon} style={{ backgroundColor: color }} alt="Chats" />
        {unreadMessages !== 0 && <div className={'mobileNewMessageCircle ' + (newMessageCircleLarge ? "mobileNewMessageCircleLarge" : "")}>{unreadMessages}</div>}
        <div className='mobileTopNavButtonLabel'>Chats</div>
      </div> 
    </div>
  );
}

export default MobileNavbar;
