import './App.css';
import Home from './Features/Home/Home';
import ProfileView from './Features/Profile/ProfileView';
import { HashRouter, Route, Routes } from 'react-router-dom';
import ProfilePage from './Features/Profile/ProfilePage';
import SearchPage from './Features/Search/SearchPage';
import ImageViewer from './Features/Menu/ImageViewer';
import MenuWindow from './Features/Menu/MenuWindow';
import MaintancePage from './Features/Pages/MaintancePage';
import ContactPage from './Features/Pages/ContactPage';
import Screening from './Features/Profile/Screening';
import PositionsPage from './Features/Positions/PositionsPage';
import PositionDisplayPage from './Features/Positions/PositionDisplayPage';
import ApplyPage2 from './Features/Careers/ApplyPage2';
import LoadData3 from './Features/DB/LoadData3';
import ChatWindow2 from './Features/Chat/ChatWindow2';
import PageActivity from './Features/ActivityLog/PageActivity';
import { isMobile } from 'react-device-detect';
import AdminPortal from './Features/AdminPortal/AdminPortal';

/*
  
  ================================================================================
  Links: 

  Repo
  https://github.com/jmarkl3/FreeFer2
  .git
  
  Hosted
  https://github.com/jmarkl3/freeFer2-ghPages
  .git
  :
  https://jmarkl3.github.io/freeFer2-ghPages

  DB
  https://console.firebase.google.com/u/0/project/test-project-5dcc3/database/test-project-5dcc3-default-rtdb/data

  Domain
  FreedomFertility.org
  https://dcc.godaddy.com/control/portfolio
  99gD@

  Hosted Main:
  https://dashboard.render.com/

  https://geolocation-db.com/dashboard
  99gL

  inspiration
  https://www.denverfertilitycare.com/

  ================================================================================
  TODO New 16-3-2024

  When new message comes in:
  If the user is scrolled all the way to bottom (or near) the scroll area scrolls to show new message
  If user is scrolled up looking at old messages the new message does not cause the scroll to move
  but there is a new message button shown hovering by the bottom of the messages area that can be clicked to scroll to bottom
    there is arrown pointing down on it, text saying new message, and the hover text says scroll to new message   

  Seems to be working properly as a minimum viable product

  expo app that can recieve notificatoins 
    if there is a message sent to that user
    if a new user visits the site, visits their profile
    would register a user there and whenever a freeFer user messages a certain userID it calls an api that sends a notification

  Add desktop UI
    basic stuff like landing page
    check all pages basic view (except chats because that will be different)
    chat list is a bar that opens messages in small windows with option to open as a seperate window 

  Integrate with admin dash
    user profile edit and creation
      similar to other app but the db structure is different
    messages
      chat list and messaging window as any user to any user

  auto reply functionality 
    would be very cool if the whole thing was automated and I just get notificatoins of things added to calendar
    this is possible, the question is if I will put it together

  Clean up code base
    Simplify and remove unnecessary files and code while keeping all functionality
    Make it more efficient where possible and without adding too much complexity

  run through user experience again to ensure everything is working

  whenever the user does anything log the time so know how long the user was on the site before they left 

  ==========
  Bugs:

  ================================================================================
  Db structure:

  freeFer:{
    users:{
      userChats:{
        userID1:{
          chatID1:{
            mostRecentMessage: {
              ...
            },
            userIDs: [userID1, userID2],
          },
          ...
        },
        ...
      },
      userProfiles: {
        userID1:{
          name: <string>,
          userType: <"green" or "blue">,
          ...
        },
        ...
      } 

    }
  }
    

  when a user loggs in would have to load their chats and profile data seperately, which is ok
  would need to save their profile data and chat data into different places, also ok
  instead of seperating users by type in the db could do a firebase query search and index by that attribute
    this is something I didn't know I could do until I created the other app

  there will be nothing in the userChats section until the user starts a chat

  ================================================================================
  TODO Main

  1) 
  email with domain

  3) 
  users

  ================================================================================
  High Priority Bugs & features:

  when in chat on mobile with keyboard open user can scroll above the chat
    maybe lock the scroll while they are in the chat window
    currently the chat window adjusts based on the screen size excluding the input
    maybe can change the body container 

  ================================================================================
  For Later:

  when user closes browser on phone the session stays open, could end the session when focus goes off the page and reopen it when it goes back


  Admin Screen
    Admin Chat
      could have a selector when in admin mode to select what attribute to search for: name, note, userID
      logging in as other users in a private tab would work for now
    seeing if a user messaged another user (and other data like which profiles they've viewd)
    just have whole chat window of another user open, not just from a specific user to another*

  look at data loading sequence

  admin screen view
    scroll doesn't work on profile pages
    doesn't snow create accout boxes
    shows wrong account when they are on their own account

  readable string for datatime and height
  sort users in search by online now and by last active

  admin chat add text menu
    adds configurable prewritten text to the chat box
    text may have the content and also a title and maybe a note
    serch box to find the text to add

  screening tab

  Search
    sort users by active first, then by last active within that for inactive users

  twillo text alerts (optional)

  account options (removed this for now)
    logout, change email, reset password
    auth forgot password button

  admin capabilities
    Send chat from any user
    set screen width based on theirs for more accurate viewing
      and adjust css media queries based on that, not the admin screen values 
    ability to click or scroll as the other user like to open the chat
    More in the admin page component

   d profiles
    random names images and locations is fine for now
    from a post: https://www.facebook.com/EGYPTLANDTRAVEL/posts/pfbid04Wp4YtofHTvxaqtuFiKnjnEqGhJthWHtzY3QyrFDE2povgt9TZehLfYnrCyYrbaZl?__cft__[0]=AZVOTRpU5SrKUe4J5xIFwCDea7T8yG8_6pfUFnem3KgE1eKKWFQIsiqL_2sqK0ZRMg1lKIV6CtGKR7pS-S8-lA4PKMmWo0WERCNZJSB3UtUtVbYzlgYT-UKJilS12ktDjQzuS2zR_4votEQCQjbRr0tbL31SuWaZ0yK91j6uWI2JqroLqkLtOoIxtzuaCdD132c7zpWR2t13AUr6l11BE0f9&__tn__=%2CO%2CP-R
    https://www.facebook.com/ken.mcewen.52
    https://www.facebook.com/groups/spermdonorsworld/posts/1775341079574274/
    https://www.facebook.com/groups/spermdonorsworld/posts/1592605234514527/


    can look at random posts
    or click a person then click a few layers deep in friends
    or search a random name like john

  Additional
  email with the site domain listed at the bottom
  contact page with inputs (same as apply but with less fields)

  more desktop styling (started, just home page now, make it like the other one)
  desktop chat like fb desktop, like in old app

   Pages:
    apply, careers, donate,
    CareersPage
      search field and list of position tiles
      when one is clicked CareersPosition window displays like the profile view window
      there is an apply box with inputs
        when user submits it pushes to db and shows a message
    ApplyPage
      apply box in a page with some content above it
      type is given to apply box for application type
    DonatePage
      UnderDevelopmentPage initially
      Some descriptions and Paypal button
    UnderDevelopmentPage
      shows an image and message saying page under maintance


  Notes:
    Domain Setup
      godaddy.com
      login 
      domains, click , click DNS
      changed godaddy dns record to 
      CNAME fre-fer-build-3.onrender.com
      A     216.24.57.1
      copied both from render.com
      clicked verify and it says certificate pending

      Summary
      Got domain on GoDaddy.com
      Created site on Render.com
      Set up Render and GoDaddy so the render site will be shown at the domain URL

    // Idk what this was
     <div style={{position: "fixed", top: "200px", left: "200px", zIndex: "100", backgroundColor:"grey",}} onClick={fetchGeo}>fetch</div>

*/

function App() {
  
  /*

  ========================================
  2024-3-26

  the video is nice but will run up the load ime and render.com bandwith
  maybe just have an image

  1) More specific adds
      its currently not super clear what the app is for
    
    Could put clickbait in a donor group and have a fb pixel in the place that it goes to for a look alike group profile

  1) Activity Log
    DONE
      view profile
        ProfileView2 useEffect addToActivityLog
      chat
        ChatWindow2 useEffect: Open Chat <chatUserID>, Closed Chat
      account actions
        LoadData3: createdAnonUser, returningAnonUser, 

        chage page
            In a page:
            (should be a better way to do that though, one code piece for all pages)
            useEffect(()=>{
              addToActivityLog("Page: Search", userID)
            },[])
        leave site 
      TODO
        active status
            in tab currently
            not in tab but tab still open
      Admin Dash side:
        calc: 
          time they spent in the app
          pages visited
          viewed profile
          sent a message

       isMobile Status

      scroll position
      
      firebase index on userType for faster loading times (expecially if user count rises)

      user visit site log item (in case the userID is not created somehow)

    2) Auto Message
          After a timer there is an auto message
            Hey, are you new to the site?
            maybe add activity log item for message sent

    3) More notifications
        new user (maybe)
        user views profile

    4) Loading time
        idk if the loadingtime is very slow, maybe change server locations in render or firebase
        render probably on states, should be in eu central, maybe check loading speed with a speed check tool
        https://tools.pingdom.com/#639edb459dc00000
        https://freedomfertility.org/
        Performance grad of 94 (A) for London 385 ms load time
        The images were the largest portion
        looks ok though

        https://whatismyipaddress.com/ip/18.133.79.92


  olivia
    watch videos
    message
  prston
    meeting
  AI
    messages


  ========================================

  FB says 21 clicks, I see 6 new users and new users are created automatically on page visit 
  so something is broken on the site of fb is lying
  update error checking
  keep track of what the user does on the site, what they clicked and viewed, how long they were on the site
  user chats load when viewing user or button to load them and show number
  if they are currently on the site
  maybe notification when a new user comes on
  a message is sent when they are still on, might want more data about how they interact first

  can have activity log sectoin
  maybe a window to open, in main view wil just show the number of items
    can have one action that takes an object and pushes that to the log with a timestamp
    clicked screen
    viewd profile

    chats view window
    see how long they stay on the site
    and if they are currently on the site
    sending a message after a timer if they are still on the site (a notificatoin will send if they respond)
    activity log


  // Unused Stuff

  const imageArrayViewerArray = useSelector(state => state.appSlice.imageArrayViewerArray)
  // ========================================
  // #region adjust for mobile keyboard height 

  useEffect(()=>{
    getHeights()
    resizeListener()
    dispatcher(toggleSyncAlternator())
    
  },[])

  const [height, setHeight] = useState()
  const [viewportHeight, setViewportHeight] = useState()
  // Want to set the height of the chat window so the top is always showing
  function resizeListener(){
    window.addEventListener("resize", getHeights)
  }
  function getHeights(event){
    // console.log(window.innerHeight)
    setHeight(window.innerHeight)
    // console.log(window.visualViewport.height)
    setViewportHeight(window.visualViewport.height)
  }

  // #endregion adjust for mobile keyboard height 
 */

  return (
    // <div className="App" style={{height: (showChat ? "calc(100% - "+(height - viewportHeight)+"px)" : "100%")}}>
    <div className="App" >
    {/* <div className="App"> */}
      {/* This sets a smaller window for dev purposes */}
      <div className={'appInner2'+(isMobile ? "":" appInner2Desktop")}>
        <HashRouter>

          <Routes>

            {/* Main Pages */}
            <Route path='/' element={<Home></Home>}></Route>
            <Route path='/search' element={<SearchPage></SearchPage>}></Route>
            <Route path='/profile' element={<ProfilePage></ProfilePage>}></Route>
            <Route path='/profile/:profileIDParam' element={<ProfilePage></ProfilePage>}></Route>
            
            {/* Secondary Pages */}
            <Route path='/screening' element={<Screening></Screening>}></Route>
            <Route path='/apply' element={<ApplyPage2></ApplyPage2>}></Route>
            <Route path='/donate' element={<MaintancePage></MaintancePage>}></Route>
            <Route path='/admin' element={<AdminPortal></AdminPortal>}></Route>
            <Route path='/contact' element={<ContactPage></ContactPage>}></Route>
            
            {/* Careers */}
            <Route path='/careers' element={<PositionsPage></PositionsPage>}></Route>
            <Route path="careers/:positionID" element={<PositionDisplayPage></PositionDisplayPage>}/>
            <Route path="careers/:positionID/:tabName" element={<PositionDisplayPage></PositionDisplayPage>}/>
            
          </Routes>

          {/* Data Interactions */}
          <LoadData3></LoadData3>
          <PageActivity></PageActivity>

          {/* Conditional Displays */}
          <MenuWindow></MenuWindow>
          <ChatWindow2></ChatWindow2>
          <ProfileView></ProfileView>
          <ImageViewer></ImageViewer>



        </HashRouter>
      </div>
    </div>
  );
}

export default App;
