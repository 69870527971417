import React, { useEffect, useRef, useState } from 'react'
import "./Navbar.css"
import chatIcon from "../../Media/chatIconInverted.png"
import acountIcon from "../../Media/accountIconInverted.png"
import menuIcon from "../../Media/menuIconInverted.png"
import searchIcon from "../../Media/searchIconInverted.png"
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setInteracted, showChat, setShowMenu, toggleShowChat, toggleShowMenu, toggleSyncAlternator, moveUsers, setViewProfileID } from '../../GlobalState/AppSlice'
import notificationSound from "../../Media/discordNotification.mp3"
import { isValidObject } from '../../Functions'
import { isMobile } from 'react-device-detect'
import MobileNavbar from './MobileNavbar/MobileNavbar'
import DesktopNavbar from './DesktopNavbar/DesktopNavbar'

function Navbar2({top, color = "#0758ff"}) {

  const userID = useSelector(state => state.appSlice.userID)
  const allUsers = useSelector(state => state.appSlice.allUsers)
  const chats = useSelector(state => state.appSlice.chats)

  const dispatcher = useDispatch()

  // ========================================
  // #region profile image account circle

  const [mainProfileImage, setMainProfileImage] = useState("")
  
  useEffect(() => {
    if(!userID || !allUsers) return
    getMainProfileImageURL()
  },[userID, allUsers])

  function getMainProfileImageURL(){
    let userData = allUsers[userID]
    let mainProfileURL = userData?.profileData?.images?.main?.downloadURL
    setMainProfileImage(mainProfileURL)

  }
  // #endregion profile image account circle

  // ========================================
  // #region calculate unread messages, play notification
  

  // The number of unread messages
  const [unreadMessages, setUnreadMessages] = useState(0)
  // Global variables used to calculate the unread messages
  const showChat = useSelector(state => state.appSlice.showChat)
  const chatUserID = useSelector(state => state.appSlice.chatUserID)

  // Displays an animation
  const [newMessageCircleLarge, setNewMessageCircleLarge] = useState()

  // So the first time around it doesn't play the new message notification
  const initialCalculateUnred = useRef(true)

  useEffect(() => {
    calculateUnreadMessages()
  },[chats])

  function calculateUnreadMessages(){
    // Ensure a valid chats object
    if(!isValidObject(chats)){      
      return
    }

    let unreadChatsTemp = 0

    Object.entries(chats).forEach(([chatID, chatDataObject]) => {
      // If the most recent message is unread and not from this user 
      if(!chatDataObject?.mostRecentMessage?.read && chatDataObject.mostRecentMessage?.from !== userID){
        
        // If the chat selector window is not open always add to the indicator (and play the new message alert)
        if(!showChat)
          unreadChatsTemp++

        // If the user is chatting with a specific user other than the one that sent the new message show the indicator (but don't play any alert)
        if(showChat && chatUserID !== chatDataObject.mostRecentMessage?.from){
          unreadChatsTemp++
        }

      }
    })

    // console.log(unreadChatsTemp+">"+unreadMessages+" = "+(unreadChatsTemp > unreadMessages))
    // If this is not the first time they are calculated and there are more than before a new message just came it
    if(!initialCalculateUnred.current && unreadChatsTemp > unreadMessages)
      messageNotification()

    setUnreadMessages(unreadChatsTemp)

    // After there are chats and the unred chats are calculated new messages are immediately recent
    initialCalculateUnred.current = false

  }

  function messageNotification(){

    // If the chat selector window is open no chat alert is necessary
    if(showChat) return

    // Play the sound
    playSound(notificationSound)

    // Play the message notification css animation
    setNewMessageCircleLarge(true)
    setTimeout(() => {
      setNewMessageCircleLarge(false)
    }, 500);

  }
  
  const audioRef = useRef()
  const playTimeout = useRef()
  function playSound(sound){

    console.log("Playing sound. Interacted: " + interacted)

    // If there is no sound return
    if(!sound) return

    // It will throw an error if the user has not interacted with the document yet
    // https://stackoverflow.com/questions/53280627/how-to-determine-if-user-interacted-with-document-to-start-video
    if(!interacted) return

    // If there is no audio player create one
    if(!audioRef.current){
      try{
        audioRef.current = new Audio(sound)
      }catch{}
    }
    
    clearTimeout(playTimeout.current)
    // If its currently playing try again in a few milliseconds
    if(!audioRef.current.paused){
      playTimeout.current = setTimeout(() => {
        // Call the function recursively so it will check and if it is paused by then it will play or call this again
        playSound(sound)
        
      }, 250);
      return
    }
    // If its paused set the new sound
    else
      audioRef.current.setAttribute("src", sound)

    // Play the sound
    try{
      audioRef.current.play()            
    }catch{}
    
  }
  // #endregion calculate unread messages

  // ========================================
  // #region interactionlistener

  const interacted = useSelector(state => state.appSlice.interacted)

  useEffect(() => {
    interactionListeners()
  }, [])

  function interactionListeners(){
    window.addEventListener("click", setInteractedFunction)
    // window.addEventListener("scroll", setInteractedFunction)
  }
  function removeInteractionListeners(){
    window.removeEventListener("click", setInteractedFunction)
    // window.removeEventListener("scroll", setInteractedFunction)
  }
  function setInteractedFunction(){
    if(!interacted)
      dispatcher(setInteracted(true))
    removeInteractionListeners()
  }

  // #endregion interactionlistener


  return (
    <div>

      {isMobile ? 
        <MobileNavbar color={color} top={top} unreadMessages={unreadMessages} newMessageCircleLarge={newMessageCircleLarge}></MobileNavbar>
        :
        <DesktopNavbar unreadMessages={unreadMessages} newMessageCircleLarge={newMessageCircleLarge}></DesktopNavbar>
      }
     
    </div>
  )
}

export default Navbar2