import React, { useEffect } from 'react'
import Search from './Search'
import { useDispatch, useSelector } from 'react-redux'
import { setViewProfileID } from '../../GlobalState/AppSlice'
import Navbar2 from '../NavBar/Navbar2'
import { isMobile } from 'react-device-detect'

function SearchPage({userApplicationState}) {

  const userID = useSelector(state => state.appSlice.userID)
  const dispatcher = useDispatch()

  function selectUser(userID){
    dispatcher(setViewProfileID(userID))
  }

  return (
    <>
        <Navbar2 userApplicationState={userApplicationState}></Navbar2>
        <div className={'searchPage '+(isMobile ? "":" searchPageDesktop")}>
            <Search selectUser={selectUser} userApplicationState={userApplicationState}></Search>
        </div>
    </>
  )
}

export default SearchPage