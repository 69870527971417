import React from 'react'
import Navbar from '../NavBar/Navbar'
import CompanyPositions from './CompanyPositions'
import Navbar2 from '../NavBar/Navbar2'

function PositionsPage() {
  return (
    <div>
        <Navbar2 top color={"#D65E4D"}></Navbar2>
        <div className='appScroller'>
            <CompanyPositions></CompanyPositions>
        </div>
    </div>
  )
}

export default PositionsPage