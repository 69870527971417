import React, { useState } from 'react'
import "./DesktopNavbar.css"
import logo from "../../../Media/freeFerLogoWordsSide2.png"
import menuIcon from "./media/hamburger-menu-icon-w.png"
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toggleShowChat } from '../../../GlobalState/AppSlice'

function DesktopNavbar() {

  const [menuOpen, setMenuOpen] = useState()

  const dispatcher = useDispatch()

  function closeMenu(){
    setMenuOpen(false)
  }

  return (
    <div className='topNav'>
        <Link to={"/"} title="Home">
            <div className='logo'>
              <img src={logo}></img>
          </div>
        </Link>
        <div className='topNavButtons'>
            <Link to={"/"}>Home</Link>
            <Link to={"/search"}>Search</Link>
            {/* <Link to={"/donate"} onClick={closeMenu}>Donate</Link> */}
            <Link to={"/careers"}>Careers</Link>
            <Link to={"/contact"}>Contact</Link>
            <Link to={"/profile"}>Profile</Link>
            <div onClick={() => dispatcher(toggleShowChat())} className='topNavButton'>Chats</div>
        </div>
        <div className='topNavButtonsMenu' onClick={()=>setMenuOpen(!menuOpen)}>
          {/* <img src={menuIcon} title="Menu"></img> */}
          <div className='menuIcon'>☰ </div>
        </div>
        <div className={'topNavButtonsMenuButtons '+(menuOpen ? " topNavButtonsMenuButtonsOpen":"")}>
          <Link to={"/"} onClick={closeMenu}>Home</Link>
          <Link to={"/search"} onClick={closeMenu}>Search</Link>
          {/* <Link to={"/donate"} onClick={closeMenu}>Donate</Link> */}
          <Link to={"/careers"} onClick={closeMenu}>Careers</Link>
          <Link to={"/contact"} onClick={closeMenu}>Contact</Link>
          <Link to={"/profile"} onClick={closeMenu}>Profile</Link>
          <div onClick={() => {dispatcher(toggleShowChat()); closeMenu()}} className='topNavButton'>Chats</div>
        </div>
    </div>
  )
}

export default DesktopNavbar